<template>
  <v-app id="app">
    <v-snackbar
      v-model="$store.state.notify.show"
      :timeout="$store.state.notify.duration"
      :color="$store.state.notify.color"
      outlined
      absolute
      right
      top
      z-index="9"
      class="mt-16"
      style="z-index: 9;"
    >
      {{ $store.state.notify.message }}
    </v-snackbar>
    <v-navigation-drawer
      v-if="isLogged"
      id="nav_drawer"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :mini-variant.sync="miniVariant"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            :src="$store.state.user.image"
            :alt="$store.state.user.name"
            :title="$store.state.user.name"
          />
        </v-list-item-avatar>

        <v-list-item-title
          class="blue3"
          :title="'Bem vindo, ' + $store.state.user.nickname "
        >
          Bem vindo, <br>
          <span v-if="$store.state.user.nickname">{{ $store.state.user.nickname }}</span>
          <span v-else>{{ (typeof $store.state.user.name === 'string') ? $store.state.user.name.split(' ')[0] : ' ' }}</span>
        </v-list-item-title>

        <v-btn
          icon
          class="button-mini"
          @click.stop="miniVariant = !miniVariant"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <sidebar-links
        :links="routes"
        :mini="miniVariant"
      />
    </v-navigation-drawer>

    <v-app-bar
      v-if="isLogged"
      id="appbar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="bk-blue2"
    >
      <v-app-bar-nav-icon
        v-if="isLogged"
        color="white"
        @click.stop="drawer = !drawer"
      />
      <v-toolbar-title class="ml-0 pl-5">
        <span class="hidden-sm-and-down">
          <img
            src="@/assets/images/logo.png"
            alt="Logo Sodré Santoro"
            title="Logo Sodré Santoro"
            style="max-width: 200px;"
          >
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-row
        v-if="isLogged"
        style="max-width: 650px; text-align: right; display: block;"
      >
        <!-- <v-btn
          icon
          color="white"
        >
          <v-icon>mdi-apps</v-icon>
        </v-btn>
        <v-btn
          icon
          color="white"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn> -->
        <v-btn
          :to="{ name: 'logout' }"
          icon
          color="white"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>
    <v-main id="content">
      <v-container fluid>
        <ss-alert
          autofocus
          :show.sync="showAlert.show"
          :title.sync="showAlert.title"
        >
          {{ showAlert.message }}
        </ss-alert>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/breakpoint.scss";
.button-mini {
  display: none;
  @include breakpoint(tablet-horizontal) {
    display: block;
  }
}
</style>

<script>
import routes from "./router/index.js";
import sidebarLinks from "./components/SidebarLinks";
import SSAlert from "./components/SSAlert";
import config from "./config";

export default {
  components: {
    "sidebar-links": sidebarLinks,
    'ss-alert': SSAlert
  },
  data: () => ({
    dialog: false,
    drawer: null,
    // routes: routes.options.routes.filter(route => route.menu),
    miniVariant: false,
    isLogged: false,
    testUsers: config.testUsers
  }),
  computed: {
    routes() {
      return routes.options.routes.filter(route => {
        if(route.menu) return true;

        if (route.name in this.testUsers) {
          return route.menu = this.checkShowExperimentalMenu(route.name)
        }
      })
    },
    showAlert() {
      return this.$store.getters.getAlert;
    }
  },
  updated() {
    this.verifyRemoveMenu();
  },
  methods: {
    checkShowExperimentalMenu(routeName) {
      const user_id = parseInt(this.$store.state.user.id)
      if (this.testUsers[routeName] && Array.isArray(this.testUsers[routeName])) {
        return this.testUsers[routeName].includes(user_id)
      }
    },
    verifyRemoveMenu() {
      if (
        this.$route.path == "/login"
        || this.$route.path == "/recover-password"
      ) {
        this.isLogged = false;
      } else {
        this.isLogged = true;
      }
    }
  }
};
</script>
